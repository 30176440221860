<template>
  <section class="content">
    <div class="card">
      <div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
      <div class="card-body">
        <table class="table table-hover" ref="tblrekshipper">
          <thead>
            <tr>
              <th rowspan="2">ID</th>
              <th rowspan="2">SHIPPER</th>
              <th rowspan="2">ATAS NAMA</th>
              <th rowspan="2">BANK </th>
              <th rowspan="2">REKENING</th>
              <th colspan="3">REQUEST</th>
              <th rowspan="2">TANGGAL</th>
              <th rowspan="2">NO TELP</th>
              <th rowspan="2">TINDAKAN</th>
            </tr>
            <tr>
              <th>ATAS NAMA BARU</th>
              <th>BANK BARU</th>
              <th>REKENING BARU</th>
            </tr>
          </thead>
          <tbody @click="handleClick"></tbody>
        </table>
      </div>
    </div>
  </section>
</template>
<script>
import { createTable, authFetch, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import "icheck-material";

export default {
  name: "List Daftar Saldo Shipper",
  data() {
    return {
      loadingContent: false,
      errors: [],
      method: "",
      //   roles: "",
      //   form: {
      //     name: "",
      //     email: "",
      //     pass: "",
      //   },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    handleClick(e) {
      if (e.target.matches("button")) {
          console.log(e.target.dataset.id);
          Swal.fire({
            title: (e.target.dataset.action == "approve" ? "Setujui" : "Tolak") + " Perubahan Rekening?",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Tidak",
          }).then((result) => {
            if (result.isConfirmed) {
              this.loadingContent = true;
              authFetch("/payment/admin/" + (e.target.dataset.action == "approve" ? "req_rek" : "unreq_rek"), {
                method: (e.target.dataset.action == "approve" ? "POST" : "PUT"),
                body: "id=" + e.target.dataset.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.table.api().ajax.reload(null, false);
                  } else {
                    Swal.fire("Proses Gagal", ``, "error");
                    //this.table.api().ajax.reload(null, false);
                  }
                }).then(() => {
                  this.loadingContent = false;
                });
            }
          });
        console.log(e.target);
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    var self = this;
    this.table = createTable(e.tblrekshipper, {
      scrollX: true,
      roles: this.$route.params.roles,
      frame: false,
      toolbarEl: e.toolbar,
      lengthChange: true,
      filter: true,
      title: "List Rekening Shipper",
      //   roles: this.$route.params.roles,
      ajax: "/payment/admin/req_rek",
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      columns: [
        { data: "shipper_code", sortable: false },
        { data: "nama", sortable: false },
        { data: "atas_nama", sortable: false },
        { data: "bank", sortable: false },
        { data: "rekening", sortable: false },
        { data: "atas_nama_new", sortable: false },
        { data: "bank_new", sortable: false },
        { data: "rekening_new", sortable: false },
        { data: "bank_requested_on" },
        { data: "notelp", sortable: false },
        {
          data: "action",
          sortable: false,
          render: function (data, type, row, meta) {
            return (`
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-info" data-action="approve" 
                  data-id="` + row.id + `">Setuju</button>
                <button type="button" class="btn btn-sm btn-warning" data-action="reject" 
                  data-id="` + row.id + `">Tolak</button>
              </div>
            `);
          },
        },
      ],
      filterBy: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      // rowCallback: function (row, data) {
      //   $("td:eq(0)", row).html(
      //     '<a class="link link-role" data-id="' +
      //       row.user_id +
      //       '" style="cursor:pointer;">' +
      //       row.nama +
      //       "</a>"
      //   );
      //   $("td:eq(3)", row).html("Rp " + formatCurrency(data.total_saldo));
      // },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
    });
  },
};
</script>